import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import MaxWidth from '../../../Layout/MaxWidth';
import { H3, Heading5, BaseText } from '../../../ui/Typography';
import { theme } from '../../../Theme';
import JournalCard from './JournalCard';
import SectionWrapper from '../../../Layout/SectionWrapper';

const CtaButton = css`
  ${Heading5}
  display: inline-flex;
  margin: 1.67rem 0;
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

const JournalWrapper = styled(SectionWrapper)`
  background: ${theme.colors.brandColor};
  padding: 1.75rem 0;

  &:last-child {
    margin-top: 1.75rem;
  }

  ${theme.below.md} {
    &:last-child {
      margin-top: 1rem;
    }
  }

  ${theme.above.md} {
    padding: 2.25rem 0;
  }

  .content {
    text-align: center;

    p.description {
      ${BaseText};
      color: ${theme.colors.white};
      max-width: 495px;
      margin: 0 auto;
    }
  }
`;

const CardsWrapper = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  padding: 1.25rem 0 2rem;

  & > * {
    width: 100%;
  }

  ${theme.above.sm} {
    padding: 2rem 0;

    &.col-1 > * {
      width: 75%;
      margin: 0 auto;
    }

    &.col-2,
    &.col-4 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${theme.between['sm-md']} {
    &.col-3 > * {
      width: 75%;
      margin: 0 auto;
    }
  }

  ${theme.above.md} {
    &.col-1 > * {
      width: 50%;
    }

    &.col-3 {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`;

const JournalBlock = block => (
  <JournalWrapper className="journal">
    <MaxWidth>
      <div className="content">
        {block.title && <H3 className="overlay">{block.title}</H3>}
        {block.articles.length > 0 && (
          <div className={cx(CardsWrapper, `col-${block.articles.length}`)}>
            {block.articles.map(article => (
              <JournalCard key={article?.content?._uid} {...article} />
            ))}
          </div>
        )}
        {block.description && (
          <p className="description">{block.description}</p>
        )}
        {block.cta_button && (
          <Link
            to={
              block.cta_button_link.cached_url.length > 0
                ? block.cta_button_link.cached_url
                : '/'
            }
            className={cx(CtaButton, 'overlay')}
          >
            {block.cta_button}
          </Link>
        )}
      </div>
    </MaxWidth>
  </JournalWrapper>
);

export default JournalBlock;
