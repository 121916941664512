import React from 'react';
import { css } from 'linaria';
import { styled } from 'linaria/react';
import MaxWidth from '../../../../Layout/MaxWidth';
import { theme } from '../../../../Theme';
import { BaseHeading, BaseText } from '../../../../ui/Typography';
import JournalCard from '../../bloks/JournalCard';

const JournalPageWrapper = css`
  margin-bottom: 5rem;

  ${theme.below.md} {
    margin-bottom: 2rem;
  }

  h1 {
    ${BaseHeading};
    font-size: 2.25rem;
    text-align: center;
    margin: 3.5rem auto;

    ${theme.below.md} {
      font-size: 1.5rem;
    }
  }

  p.description {
    ${BaseText}
    margin-bottom: 3.5rem;

    ${theme.below.md} {
      font-size: 0.875rem;
      margin-bottom: 1.5rem;
    }
  }
`;

const CustomWidth = styled(MaxWidth)`
  max-width: 880px;
`;

const CardsWrapper = css`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  & > * {
    width: 100%;
  }

  ${theme.above.sm} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${theme.above.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const JournalOverview = props => {
  const { page_title, description, articles } = props;
  if (!articles) return null;

  return (
    <div className={JournalPageWrapper}>
      <MaxWidth>{page_title && <h1>{page_title}</h1>}</MaxWidth>
      {description && (
        <CustomWidth>
          <p className="description">{description}</p>
        </CustomWidth>
      )}
      {articles.length > 0 && (
        <MaxWidth>
          <div className={CardsWrapper}>
            {articles.map(article => (
              <JournalCard key={article} {...article} minimalLayout={true} />
            ))}
          </div>
        </MaxWidth>
      )}
    </div>
  );
};

export default JournalOverview;
