import React, { useContext } from 'react';
import { css, cx } from 'linaria';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Image from '../components/Image';
import { theme } from '../../../Theme';
import { SmallText, SmallTextMedium, Heading5 } from '../../../ui/Typography';
import { LinkButton } from '../../../ui/Button';
import { Link } from 'react-router-dom';
import { handleLink } from '../../storyblok';

const SharedStyles = `
  width: 100%;
  background: ${theme.colors.white};
  text-align: center;
  display: block;

  a {
    text-decoration: none;
  }

  .content {
    padding: 1rem 0;
    ${theme.above.md} {
      padding: 1.5rem 0;
    }
  }

  .date {
    color: ${theme.colors.grey};
  }

  .summary {
    text-align: left;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    
    ${theme.above.md} {
      -webkit-line-clamp: 2;
    }
  }

  h2 {
    ${Heading5};
  }
`;

const MinimalArticle = css`
  ${SharedStyles};

  h2 {
    margin: 0.5rem 0;
  }
`;

const FeaturedArticle = css`
  ${SharedStyles};
  position: relative;

  &:hover {
    .cta-button a {
      border-color: ${theme.colors.black};
    }
  }

  .image-wrapper {
    width: 100%;
    height: 235px;

    ${theme.above.md} {
      height: 285px;
    }
  }

  .content {
    padding: 2.875rem 1.25rem;
    ${theme.above.md} {
      padding: 4rem 1.25rem;
      margin: 0 auto;
    }
  }

  .summary {
    text-align: center;
    max-width: 385px;
    margin: 0 auto;
  }

  h2 {
    margin: 1.5rem 0;
  }

  .cta-button {
    margin: 0 auto;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    ${theme.above.md} {
      margin-top: 2rem;
    }
  }

  .cta-button a::after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer;
  }
`;

const JournalCard = ({ minimalLayout, ...article }) => {
  const { selectedChannel } = useContext(ChannelContext);
  const articlePublishedDate = new Date(
    article?.first_published_at
  ).toLocaleDateString(selectedChannel.language.culture);

  if (minimalLayout)
    return (
      <article className={MinimalArticle}>
        <Link to={handleLink(article?.full_slug)}>
          <div className="image-wrapper">
            <Image
              width={432}
              aspect="16:9"
              src={article?.content?.hero_image?.filename}
            />
          </div>
          <div className="content">
            <span className={cx(SmallTextMedium, 'date')}>
              {articlePublishedDate}
            </span>
            <h2>{article?.content?.page_title}</h2>
            {article?.content?.summary && (
              <p className={cx(SmallText, 'summary')}>
                {article.content.summary}
              </p>
            )}
          </div>
        </Link>
      </article>
    );

  return (
    <article className={FeaturedArticle}>
      <div className="image-wrapper">
        <Image
          width={1328}
          fillAvailableSpace={true}
          src={article?.content?.hero_image?.filename}
        />
      </div>
      <div className="content">
        <span className={cx(SmallTextMedium, 'date')}>
          {articlePublishedDate}
        </span>
        <h2>{article?.content?.page_title}</h2>
        {article?.content?.summary && (
          <p className={cx(SmallText, 'summary')}>
            {article?.content?.summary}
          </p>
        )}
        {article?.content?.cta_button && (
          <div className="cta-button">
            <LinkButton
              to={handleLink(article?.full_slug)}
              className="secondary"
            >
              {article?.content?.cta_button}
            </LinkButton>
          </div>
        )}
      </div>
    </article>
  );
};

export default JournalCard;
